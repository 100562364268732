@CHARSET "UTF-8";
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-top: 50px;
}
body.embed {
  margin-top: 0;
}

.link-ncs{
  text-decoration: underline;
}
.link-dash-well{
  color: white;
  text-decoration: underline;
}

.page{
  margin: 0 55px;
  padding-top:15px;
  padding-bottom:110px;
}
@media (max-width: 767px) {
  .page{
    margin: 0 10px;
    padding-top:15px;
    padding-bottom:50px;
  }
}
body.embed .page {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.wrapper {
  min-height: 100%;
  height: auto;
  margin: 0px 55px -120px 0;
  padding-bottom: 125px;

}
.hex-logo
{
  margin-top: -10px;
}
.fill-height {
  min-height: 100%;
  height: auto;
}

.add-gutter{
  padding: 0.5rem;
}


.well-ncs {
  min-height: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: -20px;
  /* background-color: #; */
  border: 0px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}
.navbar-ncs {
  background-image: -webkit-linear-gradient(top, #465964 0%, #465964 100%);
  background-image:      -o-linear-gradient(top, #465964 0%, #465964 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#465964), to(#465964));
  background-image:         linear-gradient(to bottom, #465964 0%, #465964 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
}
.navbar-ncs .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #465964 0%, #465964 100%);
  background-image:      -o-linear-gradient(top, #465964 0%, #465964 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#465964), to(#465964));
  background-image:         linear-gradient(to bottom, #465964 0%, #465964 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#465964', endColorstr='#465964', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, .25);
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, .25);
}
.navbar-ncs .navbar-brand,
.navbar-ncs .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
}
.navbar-brand{
  color: #fff;
}

.navbar-brand:hover{
  color: #fff;
}

.nav-ncs{

  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-ncs>li>a {
  color: #000;
  text-decoration: none;
  list-style: none;
}


.nav-ncs>li>a:focus, .nav-ncs>li>a:hover {
  text-decoration: underline;

}
.sidebar-ncs{
  height: auto;

  /*background-color: #eee;
  border-right: thin solid #000;*/
}
.dashboard-middle{
  position: fixed;
  left: 215px;

}
.dashboard-pannel{

}
.text-center{
  text-align: center;
}
.well-center{

  min-height: 96px;
  position: relative;
}
.well-info-icon {
  position: absolute;
  bottom: 12px;
  right: 8px;
  z-index: 100;
}
.sidebar-group-ncs{
  margin-bottom: 30px;
  #border-bottom: thin solid #000;


}
.dash_header{
  font-size: 1.5em;
}
.dash_header_report_link{
  text-align: right;
}

.dashboard-well{
  font-size: 4em;
  padding: 20px;
}
.dashboard-well-text{
  font-size: 1.1em;
  line-height: 1em;
}
.dashboard-well-line{
  margin-top: 7px;
  display: block;
}

.dashboard-well-text-num{
  font-size: 1.8em;
  line-height: 1.8em;
}

.dashboard-well-text-num2{
  font-size: 1.3em;
  line-height: 2.25em;
}

@media (max-width: 1155px) {
  .dashboard-well {
    font-size: 2em;
  }
  .dashboard-well-text{
    font-size: .8em;

  }
  .dashboard-well-text-num{
    font-size: 1.3em;
  }

  .dashboard-well-text-num2{
    font-size: 1.0em;
  }
}

.sidebar-header-ncs{
  white-space: nowrap;
  color: #000;
  font-size: 1.1em;


}
.sidebar-text-ncs{
  white-space: nowrap;
  font-size: 1.0em;
}

.sidebar li a {
  line-height: 30px;
  height: 30px;
  padding-top: 0;
}

.csg_filter{
  width: 200px;

}

.footer a {font-size:10px; color:#fff}

.footer	{
  height:50px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #465964;
  color:#fff;

}
.footerLeft{
  padding-top: 10px;
  position: relative;
  text-align: left;
  font-size: 1.1em;
  float: left;
}

.footerRight{
  padding-top: 15px;
  position: relative;
  text-align: right;
  font-size: 1.1em;
  float: right;
}

.socal{

  font-size: 2.0em;

}

.carousel-inner > .item > img {
  margin: 0 auto;
}
.carousel-inner img {
  margin: auto;
}
.carousel-control .fa-chevron-left, .carousel-control .icon-prev {
  margin-left: -15px
}

.carousel-control .fa-chevron-right, .carousel-control .icon-next {
  margin-right: -15px
}
.carousel-control .fa-chevron-left, .carousel-control .fa-chevron-right,
.carousel-control .icon-next, .carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block
}

.carousel-control .fa-chevron-left, .carousel-control .icon-prev {
  left: 50%;
  margin-left: -10px
}

.carousel-control .fa-chevron-right, .carousel-control .icon-next {
  right: 50%;
  margin-right: -10px
}

@media screen and (min-width:768px) {
  .carousel-control .fa-chevron-left, .carousel-control .fa-chevron-right,
  .carousel-control .icon-next, .carousel-control .icon-prev {
    width: 100px;
    height: 30px;
    margin-top: -15px;
    font-size: 30px
  }

  .carousel-control .fa-chevron-left, .carousel-control .icon-prev {
    margin-left: -15px
  }

  .carousel-control .fa-chevron-right, .carousel-control .icon-next {
    margin-right: -15px
  }

  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px
  }

  .carousel-indicators {
    bottom: 20px
  }
}

#right
{
  float: right;

}

@media (max-width: 850px) {
  .hidetop{

    display: none;


  }

}

.vcenter {
  vertical-align: middle;
}

.pointer
{
  cursor: pointer;
}

.header-padding{
  padding: 0 5px;
}
.notification-circle {
  display: inline-block;
  height: 34px;
  width: 40px;
  line-height: 34px;

  -moz-border-radius: 30px; /* or 50% */
  border-radius: 30px; /* or 50% */


  color: white;
  text-align: center;
  font-size: 1em;
}
.no-hover:hover{
  color: white;
  text-decoration: none;
}/*
.overflow{
    overflow-y: visible;
    position: relative;
}*/
.footer-inside-modal{
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  justify-content: flex-end;
}
.modal-padding{
  padding: 15px;
}
.text-white{
  color: white;
}
@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: relative;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}
#snowflakeContainer {
  position: absolute;
  left: 0px;
  top: 0px;
}
.snowflake {
  padding-left: 15px;
  font-family: Cambria, Georgia, serif;
  font-size: 14px;
  line-height: 24px;
  position: fixed;
  color: #FFFFFF;
  user-select: none;
  z-index: 1000;
}
.snowflake:hover {
  cursor: default;
}
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
  color: #212529;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.searchclear {
  position: absolute;
  right: 25px;
  top: 0;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
}
.cut-off{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.search-box-style{
  padding: 10px;
  background-color: #eeeeee;
  border-radius: .7em;
}
.tablesorter-background{
  background-color: white;
}
.affix {
  top:50px;
  width: 15.8%;
}
.affix-bottom {
  position: absolute;
}
#sidebar.row.affix-bottom{
  position: relative;
  width: 117%;
}
.no-margin{
  margin: 0;
}

.doc-editor{
  width:100%;
  height:1000px;
}

.color-black{
  color: black;
}
.no-wrap{
  white-space:nowrap;
}
.highlight{
  background-color: #E8E8E8;
}
.toaster{
  position: fixed;
  z-index: 1031;
  display: inline-block;
  right: 10px;
  top: 20px;
}
.saved_searches.dropdown-menu>li>a:after{
  content: none;
}

@media print {
  .myDivToPrint {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    font-size: 10px;
    display: block;
  }
  .table-condensed>thead>tr>th, .table-condensed>tbody>tr>th, .table-condensed>tfoot>tr>th, .table-condensed>thead>tr>td, .table-condensed>tbody>tr>td, .table-condensed>tfoot>tr>td{
    padding: 2px;
  }
  .footer, .btn{
    display: none;
  }
  .print_area{
    display: block;
  }
}

@media screen {
  #forPrintScreenOnly {
    display: none;
  }
}


.selection-required{
  background: #fcf8e3 !important;
  color:#000;
  text-shadow:0 1px 0 rgba(0, 0, 0, 0.4);
}
.list-group > .ucc_list{
  margin-bottom:0;
  border-radius: 1em;
  border: 2px solid #e3e3e3;
  /*-webkit-user-select: none; !* webkit (safari, chrome) browsers *!*/
  /*-moz-user-select: none; !* mozilla browsers *!*/
  /*-ms-user-select: none; !* IE10+ *!*/
}

.card_selected {
  position: sticky;
  top: 62px;
  bottom: 16px;
  z-index: 2;
}

.card_selected ul {
  -webkit-box-shadow: 0 6px 13px slategrey;
  -moz-box-shadow: 0 6px 13px slategrey;
  box-shadow: 0 6px 13px slategrey;
  transition: 0.5s;
}
.card_selected ul > .ucc_default{
  border: 3px solid #00aeef;
}
.card_selected ul > .ucc_danger{
  border: 3px solid #d9534f;
}
.search-box-ucc{
  margin-bottom: 0.2em;
}
.search-box-section{
  padding-bottom: .7em;
  border-bottom: solid 1px darkgray;
}
.search-box-style h4{
  margin-bottom: 5px;
}
.bkdata_ucc_priority{
  background-color: #C70012;
}
.border-top-blue {
  border-top: 5px ridge rgba(212,221,228,.5);
}
@media (max-width: 768px) {
  #launcher{
    top:-8px !important;
    right:50px !important;
  }
}
.default_star:before{
  color: #FFD700;
  font-weight: 900 !important;
}
.default_star:hover:before{
  color: black;
  font-weight: 400 !important;
}
.make_star_default:before{
  color: black;
  content:"\f005";
}
.make_star_default:hover:before{
  color: #FFD700;
  font-weight: 900 !important;
}
button:hover > .make_star_default:before{
  color: #FFD700;
  font-weight: 900 !important;
}
.panel > .ucc_note_group:last-child .list-group-item:last-child{
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
.rotate45{
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}
.note_type_badge{
  margin-right: 3px;
}
.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ucc_panel_border{
  border-radius: 1em;
  margin-bottom: 10px;
}
.not_link{
  color:black;
}
.not_link:hover{
  color: black;
  text-decoration: none;
}
.ucc_priority{
  color: #d9534f;
}
.ucc_follow_up_badge{
  color:white;
  background-color: #d9534f;
}
.ucc_panel_header{
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}
.margin_top{
  margin-top: -50px;
}
.ucc_text_danger{
  color: #d9534f;
}
.panel-heading.pointer.collapsed{
  -webkit-border-radius: 1em !important;
  -moz-border-radius: 1em !important;
  border-radius: 1em !important;
}
.ucc_panel_arrow{
  margin-left: 5px;
  transition: transform .38s;
  transform: rotate(180deg);
}
.collapsed .ucc_panel_arrow{
  transition: transform .38s;
  transform: rotate(0deg);
}

.rotate-180 {
  transform: rotate(180deg);
}


.edit_homepage_option{
  border-radius: 1em;
  border: 3px solid lightgrey;
}
.ucc_widget_item{
  flex:1;
  text-align: center;
  padding: 5px;
}
.ucc_widget_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  margin-top: 10px;
  max-height: 400px;
}
.gray-block{
  background-color: lightgrey;
  height: 20px;
  display: inline-block;
}
.homepage-widget{
  box-shadow: lightgrey 0 10px 22px;
  margin: 35px 15px;
  border-radius: .5em;
  border: 2px solid #efefef;
}
.ytd_ticker{
  white-space: nowrap;
  font-size: 28px;
  margin-top: 12px;
  display: block;
}
.text-green{
  color: #009800d6;
}
.text-red{
  color: #d9534f;
}
.button-container{
  display: flex;
  align-content: center;
  margin-top: -10px;
  margin-bottom: 10px;
}
.button-items{
  flex:1;
  text-align: center;
}
.service_select:hover{
  background-color: #f5f5f5;
}

.copy-section-header {
  margin: 20px 0px;
}

.copy-icon {
  padding-right: 9px;
  color: grey;
}

.check-icon {
  color: green;
}

.copy-data-row {
  margin: 7px 0;
  padding: 6px 0;
}

.copy-label {
  text-align: right;
  font-weight: bold;
}

.inline-copy {
  color: #6d6c6c;
  cursor: pointer;
  background-color: rgb(240, 240, 240);
  padding: 5px;
  padding-right: 13px;
  border-radius: 5px;
}

.limit-size{
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.limit-search-box-size{
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.inline-copy:hover {
  background-color: rgb(224, 224, 224);
}

.inline-copy:active {
  background-color: rgb(209, 209, 209);
}

.inline-copy .far {
  padding-left: 10px;
}

.docuware-list {
  overflow: auto;
  /* height: 500px; */
  /* display: block; */
}

.docuware-list th, .docuware-list td {
  width: 300px;
  vertical-align: middle;
}

.docuware-modal .modal-content .docuware-content {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.doc-upload-form {
  padding: 7px;
}


.ucc_alert{
  z-Index: 9999;
  right: 20px;
  top: 20px;
  list-style-type: none;
  position: fixed;
}

@media (max-width: 767px) {
  .ucc_alert{
    bottom: 0;
    right: 0;
    top: auto;
    width: 100%;
  }
}

.alert_button_wrapper{
  top:0;
  position:absolute;
  bottom:0;
  right:0;
  margin-top:-12px;
  padding:0;
  margin-bottom:-13px;
  display:flex;
  margin-right: -5px;
}

.alert_button_group{
  display:flex;
  flex-direction:column;
  flex:1 100%
}

.alert_button_group .alert_button:first-child{
  border-top: 0;
}

.alert_button_group .alert_button:last-child{
  border-bottom: 0;
}

.alert_button{
  border-left: 2px solid #cecece;
  border-right: 0;
  flex: 1 auto;
  background-color: transparent;
  cursor: pointer;
}

.alert_button:hover{
  background-color: #a1cce2;
}

.reply_wrapper{
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  padding:0;
  display:flex;
}

.reply_group{
  display:flex;
  flex-direction:row;
  flex:1 100%
}

.reply_button{
  border-top: 2px solid #cecece;
  flex: 1 auto;
  background-color: transparent;
  cursor: pointer;
}

.reply_group .reply_button:last-child{
  border-left: 2px solid #cecece;
  border-bottom-right-radius: .25em;
}

.reply_group .reply_button:first-child{
  border-left: 2px solid #cecece;
  border-bottom-left-radius: .25em;
}

.reply_button:hover{
  background-color: #a1cce2;
}

.reply-note{
  padding: 5px 10px;
  border-bottom: 2px solid #cecece;
}

.reply-container .reply-note:last-child{
  border-bottom: 1px solid #cecece;
}

.ucc_note_group > span:first-child > .parent_note{
  border-top: none;
}
.ucc_note_group > span:last-child > .list-group-item{
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em;
}

.ucc_note_tabs>li{
  margin-left: -1px;
}

@keyframes slideInFromRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; }
}

@-webkit-keyframes slideInFromRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; }
}

@-moz-keyframes slideInFromRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; }
}

@keyframes slideOutToRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px,0,0);
  }
  100% {
    opacity: 0;
    transform: translate3d(2000px,0,0);
    visibility: hidden;
  }
}

.ucc-alert-item{
  animation-name: slideInFromRight;
  animation-fill-mode: both;
  animation-duration: 1s;
  transition: transform 0.2s ease 0s,opacity 0.2s ease 0s;
  transform: translateX(0);
  box-shadow: 0 6px 13px slategrey;
}

.hide-alert {
  animation-name: slideOutToRight;
  animation-fill-mode: both;
  animation-duration: 1s;
  transition: transform 0.2s ease 0s,opacity 0.2s ease 0s;
  transform: translateX(0);
}

.col-xs-0, .col-sm-0, .col-md-0, .col-lg-0 {
  flex: 0 0 0 ;
  width: 0%;
  position: relative;
  float: left;
  min-height: 1px;
  opacity: 0;
}

#save-template-text {
  transition: .6s;
  width: 30px;height: 30px;
  border-radius: 20px;border: none;
  cursor: pointer;
  background: white;
}
#save-template-button {    color: #696969    }
/* #save-template-text:hover {
	color: white;
	background: rgb(200, 200, 200);
	box-shadow: 0 0 0 5px rgb(61, 71, 82);
}
#save-template-text:hover #save-template-button:hover {    color: white    } */
.save-template-text-focus {
  transition: .6s;
  border: none;outline: none;
  border-radius: 20px;border: none;
  box-shadow: none;
  padding-left: 35px;
  cursor: text;
  width: 300px;height: 30px;
  border-radius: auto;
  background: #f7f7f7;
  color: black;
}

.save-template-text-blur{
  text-indent:-5000px;
  width: 30px;height: 15px;
  transition:  .6s;
  background: white;
  border-radius: 20px;border: none;
}
#save-template-button:focus {    color: black;    }

#save-template-button {
  position: relative;
  right: -25px;
  color: #3e3e3e;
  cursor: pointer;
}
.simple-button{
  transition: .2s;
}
.simple-button:hover {
  color: #63c0e6;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 2
}

.table-row-selected td {
  background-color: #f0f5f7;
}

.flex-copy{
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
  justify-content:space-between;
}

.flex-label{
  flex-basis:40%;
  white-space: nowrap;
  overflow-wrap: break-word;
}

.flex-copy-button{
  flex-basis:60%;
  white-space: nowrap;
  overflow-wrap: break-word;
}

.flex-row{
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  justify-content:flex-start;
  margin: 20px;
}

.purple{
  color:#b300b3
}

.flex-bottom{
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  padding: 0;
}

.flex-top{
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  padding: 0;
}

.flex-stretch{
  display: flex;
  flex-direction: row;
  flex: 1;
  align-content: stretch;
}

.flex-button{
  flex: 1;
  background-color: transparent;
  padding: 8px;
  border: 1px solid #ddd;
}

.flex-stretch > .flex-button:first-child{
  border-left: transparent;
  /*border-bottom-left-radius: 1em;*/
}

.flex-stretch > .flex-button:last-child{
  border-right: transparent;
  /*border-bottom-right-radius: 1em;*/
}

.flex-bottom > .flex-stretch > .flex-button{
  border-bottom: transparent;
}

.flex-top > .flex-stretch > .flex-button{
  border-top: transparent;
}

.no-flex-border > .flex-button{
  border-left: transparent;
  border-right: transparent;
}

.flex-button:hover{
  background-color: #cecece40;
}

.hidden{
  display: none !important;
}

.ucc_closed{
  background-image: none !important;
  background-color: #d4d4d4 !important;
}

.ucc_open{
  background-image: none !important;
  background-color: #f1f1f1 !important;
}

.ucc_open.ucc_panel_danger{
  background-image: none !important;
  background-color: #f2dede !important;
}

.ucc_closed.ucc_panel_danger{
  background-image: none !important;
  background-color: #e6a6a6 !important;
}

.ucc_log.ucc_widget_item{
  border-radius: 1em;
}

.ucc_log.ucc_widget_item:hover{
  box-shadow: #e5e5e5 0 4px 12px;
  /*border: 1px solid lightgrey;*/
}

.emp-error-selected {
  -webkit-box-shadow: 0 6px 13px slategrey;
  -moz-box-shadow: 0 6px 13px slategrey;
  box-shadow: 0 6px 13px slategrey;
  transition: 0.5s;
  border: 2px solid #00aeef !important;
}

.flat-button {
  padding: 10px 15px;
  margin: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #989898;
  background-color: #efefef;
  border-radius: 7px;
  cursor: pointer;
  transition: .2s background-color;
  border-color: #ffffff00
}

.flat-button:hover {
  color: #717171;
  background-color: #e6e6e6;
}

.flat-button:active {
  color: #717171;
  background-color:#dadada
}

.search-title {
  font-size: 16px;
  color: #868686
}

.file-dropper {
  width: 100%;
  height: 100px;
  background-color: #fdfdfd;
  border-radius: 10px;
  border: 2px dashed gainsboro;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #c5c5c5;
  font-weight: 300;
  cursor: pointer;
  transition: .2s;
  font-size: 15px;
}

.file-dropper:hover {
  background-color: #f7f7f7;
}

.file-dropper:active {
  background-color: #ececec;
}

.email-header {
  border-bottom: 1px solid #f3f3f3;
}

.email-header td:first-child {
  padding-top: 7px;
  vertical-align: top;
  text-align: right;
}

.email-header td:first-child span {
  color: grey;
  padding: 0px 8px;
  display: inline-block;
}

.email-header td:nth-child(2) {
  width: 100%;
}

.unstyled-input {
  border: 0px;
  box-shadow: inset 0px 0px 0px 0px white !important;
  width: 100%;
  display: inline-block;
  padding-left: 0px;
  outline: none;
}

.request-card{
  padding: 20px;
  border: 1.5px solid #e3e3e3;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
  margin-bottom: 15px;
}

.border-curved{
  border-radius: 1em;
}

.border-curved > *:first-child{
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}
.border-curved > *:last-child{
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.left-nav {
  left: 0;
  border-right: 1px solid #f3f3f3;
}

.side-nav{
  position: absolute;
  top: 0;
  z-index: 1029;
  bottom: 0;
  width: 220px;
  background-color: #f9f9f9;
  border-right: 1px solid #e2e2e2;
  /*overflow: scroll;*/
}
.side-nav-collapsed:hover{
  left:0;
}

.side-nav-collapsed:hover div{
  left:0;
  float: none;
}

.side-nav-collapsed{
  /*width: 55px !important;*/
  transition: 0.3s;
  left: -165px;
  cursor: pointer;
}

.side-nav-collapsed{
  text-align: right;
}

.side-nav-collapsed:hover{
  text-align: left;
}

.side-nav-collapsed .hide-side-nav-collapse{
  display: none;
}

.side-nav-collapsed:hover .hide-side-nav-collapse{
  display: inline !important;
}

.side-nav-filters{
  padding: 15px;
  background-color: transparent;
  color: rgb(90, 90, 90);
  cursor: auto;
}

.right-nav{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  border-left: 1px solid #f3f3f3;
}

.side-nav-header{
  padding: 15px;
  border-bottom: 1px solid #e2e2e2;
}

.side-nav-body{
  position: absolute;
  top:112px;
  bottom: 51px;
  left: 0;
  right: 0;
  overflow: auto;
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

.side-nav-footer{
	bottom: 0;
	position: absolute;
	padding: 15px 20px;
	border-top: 1px solid #e2e2e2;
	width: 100%;
	background-color: #f9f9f9;
}

.side-nav-footer:hover{
	background-color: #eeeeee;
}

.file-bar {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 300px;
	background-color: #f9f9f9;
	border-bottom: 1px solid #e2e2e2;
}

.file-bar-collapsed {
	height: 100px;
}

.file-bar-footer {
	position: absolute;
	bottom: 0;
	border-top: 1px solid #e2e2e2;
	width: 100%;
	height: 20px;
	background-color: #f9f9f9;
	text-align: center;
	color: grey;
	margin-left: -15px;
}

.file-bar-footer:hover {
	background-color: #eeeeee;
}

.file-content {
	margin-right: 220px;
	position: relative;
	overflow: auto;
	top: 300px;
}

.file-content-collapsed {
	margin-right: 220px;
	// top: 100px;
	position: relative;
	overflow: auto;
    height: 100%;
}

.file-bar .row {
	padding: 5px;
}

.file-bar-header {
	border-bottom: 1px solid #e7e7e7;
	height: 35px;
}

.address-modal {
	max-width: 1300px;
}

.tracker-content{
  /* position: absolute; */
  /* top: 45px; */
  bottom: 0;
  margin: 0 220px;
  overflow: auto;
  padding: 0 10px;
}

.page-container{
  position: absolute;
  top: 56.5px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.page-container #app {
  height: 100%;
}

.page-container #app > div {
  height: 100%;
}

.main-content{
  margin-left: 220px;
  /*margin-right: 220px;*/
  position: relative;
  /* margin-top: 45px; */
  height: 100%;
  /*overflow: scroll;*/
}

.extra-content{
  height: 100%;
  overflow: auto;
}

.main-content-collapsed{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 55px;
  position: relative;
  height: 100%;
}

.typeaheadcard {
  margin-left: 250px;
  margin-right: 224px;
  position: relative;
  margin-top: 50px;
}

.projectcard{
  margin-left: 250px;
  margin-right: 224px;
  position: relative;
  margin-top: 50px;
}

.list-nav-item{
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;
  background-color: transparent;
  color: #272727;
}

.list-nav-item:hover{
  background-color: #e7e7e7;
}

.side-nav-footer:hover{
  background-color: #eeeeee;
}

.list-nav-item > a {
  color: #5a5a5a;
}

.list-nav-item > a:hover {
  text-decoration: none;
}

.nav-submenu{
  display: block;
  position: absolute;
  /*top: 0;*/
  left: 75%;
  z-index: 3333;
  float: left;
  margin-top: -35px;
}

.list-nav-item:hover .portal-nav-menu{
  display: block !important;
}

.list-nav-item.open{
  background-color: rgba(0,0,0,0.04)!important;
  border-left: 4px solid #00a651;
}

.list-nav-header{
  display: block;
  padding: 15px 20px;
}

.list-nav-item.open .list-nav-header {
  color: #00a651;
}

.list-nav-item.open > .list-nav-header:hover{
  background-color: #e7e7e7 !important;
}

.nav-link-selected{
  font-weight: bold;
  background-color: #e7e7e7 !important;
  color: #00a651 !important;
}

.sub-list-nav-item{
  padding:  15px 15px 15px 35px;;
  background-color: transparent;
  display: block;
  color: #5a5a5a;
}

.sub-list-nav-item:hover {
  text-decoration: none;
  /* color: black; */
}

.sub-list-nav-item:hover{
  background-color: #d7d7d7;
}

.side-nav-footer{
  bottom: 0;
  position: absolute;
  padding: 15px 20px;
  border-top: 1px solid #e2e2e2;
  width: 100%;
  background-color: #f9f9f9;
}

.side-nav-menu-hover{
  position: fixed;
  display: block;
}

.click-to-edit {
  background-color:transparent;
  border-color:transparent;
  border-radius:6px;
  transition: .5s;
}

.click-to-edit:focus {
  background-color:white;
  border-color:grey;
}

.right-nav-body {
  padding: 15px;
}

.right-nav-body h5 {
  font-weight: bold;
  padding-top: 15px;
}

.click-to-edit:hover {
  background-color: #efefef;
  border-radius: 6px;
  border-color: transparent;
  color: #807e7e;
}

.info-label {
  display: block;
  font-size: 12px;
  padding-top: 5px;
  color: grey;
  font-style: italic;
}

.info-value {
  font-weight: bold;
  color: #525252;
  transition: .1s;
}

.info-value:hover {
  /* background-color:#e4e4e4;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 4px #e4e4e4; */
  cursor: text;
}

/* .info-edit {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #83a3ff;
} */

.opacity-0 {
  opacity: 0;
}

@keyframes fade-in-out {
  0%  {opacity: 0}
  50% {opacity: 100}
  100% {opacity: 0}
}
.faa-fade-in-out {
  animation: fade-in-out 1s ease-in-out;
}

.input-no-style {
  background-color: transparent;
  border: transparent;
  padding: 0;
  font-weight: bold;
  resize: none;
}

.info-edit::placeholder {
  font-weight: normal;
  font-style: italic;
  color: rgb(206, 206, 206);
}

.card-nml{
  transition: box-shadow 0.1s ease-in-out;
  cursor: pointer;
}

.card-nml:hover{
  -webkit-box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
}

.nml-card-selected{
  border: 2px solid #00a651 !important;
  -webkit-box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 50px;
  bottom: 10px;
  z-index: 50;
}

.nml-card-selected .card-header {
  color: white;
}

.main-content-col {
  height: 100%;
  top:0px;
  bottom: 0px;
  overflow: auto;
  position:absolute;
}

.file-list {
  left: 0px;
  width: 50%;
}

.file-preview {
  right: 0px;
  width: 50%;
}

.file-content {
  margin-right: 220px;
  position: relative;
  height: 100%;
  overflow: auto;
}
@media print {
  .no-print{
    display: none;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

.dropright:hover > .dropdown-menu {
  left: 98% !important;
}

.list-nav-item .collapsible .collapsed {
  transform:scaleY(0);
}

.list-nav-item .collapsible {
  overflow:hidden;
  transition:transform 0.3s ease-out;
  height:auto;
  transform:scaleY(1);
  transform-origin:top;
}

.ucc-card-header.collapsed{
  border-bottom-right-radius: 1em !important;
  border-bottom-left-radius: 1em !important;
  border-bottom: transparent;
}

.flex-bottom > .flex-stretch > .flex-button:first-child{
  border-bottom-left-radius: 1em;
}

.flex-bottom > .flex-stretch > .flex-button:last-child{
  border-bottom-right-radius: 1em;
}

.content-col{
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  //overflow-y: auto;
}

.ucc_note_group > .list-group-item:first-child{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ucc_note_group > .list-group-item:last-child{
  border-bottom-right-radius: 1em !important;
  border-bottom-left-radius: 1em !important;
}

.nml-section{
  border: 2px solid;
  border-radius: 1em;
  padding: .75em 1em;
  box-shadow: 0 7px 13px -3px rgba(0, 0, 0, 0.1);
}

.carousel-inner{
  overflow: visible !important;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.docuware-footer{
  padding-bottom: 1rem !important;
}

.docuware-footer>:not(:last-child) {
  margin-right: .25rem;
}

.list-group-item-selected{
  border: 2px solid rgb(0, 174, 239) !important;
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 555;
}

.list-nav-item:hover > .accordion > .collapse.show{
  background-color: transparent;
}

.short-dropdown-menu{
  max-height: 400px;
  overflow-y: scroll;
}

.dropdown-menu > .dropdown-item{
  cursor: pointer;
}

.file-drag-over{
  border: 2px solid lightblue;
  border-radius: .25rem;
  margin: 20px;
}

.notice-add-hover:hover{
  background-color: lightgray;
}

.bg-hover:hover{
  background-color: rgba(0,0,0,.04);
}

.message-list-item:hover{
  background-color: #dee2e6;
}

.message-selected{
  background-color: #dee2e6;
}

.chat-message{
  display: block;
  clear: both;
  max-width: 60%;
  white-space: pre-line;
}

.message-bubble{
  border-radius: 1rem;
  color: white;
  padding: 10px;
}

.message-sender{
  float: right;
  padding-right: 10px;
  text-align: right;
}

.message-sender > small{
  float: right;
}

.message-sender > .message-bubble {
  background-color: #1481ff;
}

.message-receiver > .message-bubble {
  background-color: darkgray;
}

.message-receiver{
  padding-left: 10px;
  float: left;
  text-align: left;
}

.message-receiver > small{
  float: left;
}

.chat-link{
  color:#ffffff;
  text-decoration: underline !important;
}

.message-attachment > .img-thumbnail{
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.download-message-attachment{
  cursor: pointer;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  margin-bottom: 5px;
}

.download-message-doc{
  min-width: 200px;
  text-align: left;
  border: 1px solid white;
  padding: 6px;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
}

.stack-icons{
  position: relative;
}

.stack-icons>.badge{
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: .5em;
}

.reflektive-add-avatars{
  font-family: Graphik, system-ui !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 46px !important;
  background-color: rgb(194, 202, 210) !important;
  color: rgb(255, 255, 255) !important;
}
.reflektive-picture{
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.reflektive-picture > img{
  width: auto;
  height: 100%;
  position: absolute;
}
.reflektive-rec-names {
  color: #8796a6;
  font-size: 9px;
  margin-top: 3px;
}
.reflektive-sender-name {
  font-family: Graphik,system-ui;
  font-weight: 500;
  font-size: 10px;
  line-height: 1;
  font-style: normal;
  color: #1f2539;
  display: inline-block;
  vertical-align: top;
}

.reflektive-scroll {
  overflow-y: scroll;
  height: calc(100vh - 155px);
}

.graph-widgets-scroll {
  overflow-y: scroll;
  height: calc(100vh - 98px);
}

#reflektive_col.collapse {
  height: auto;
  -webkit-transition: width 0.35s ease;
  -moz-transition: width 0.35s ease;
  -o-transition: width 0.35s ease;
  transition: width 0.35s ease;
}

.th-sticky-header{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
  background-color: white;
}

@media (max-width: 1200px) {
  .sortableTable-responsive{
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.styled-input{
  border: 0;
  box-shadow: inset 0 0 0 0 white;
  display: inline-block;
  padding-left: 0
}

.sortableTableStickyRow{
  position: sticky;
  background-color: white;
  bottom: 5px;
  z-index: 1020;
}

.labeled-input.required {
  content:"*";color:red;
}

i.fas.fa-asterisk {
  color: red;
  font-size: 7px;
  position: relative;
  top: -5px;
  right: -3px;
}
.search-list > .row {
  border-top: 1px solid #ddd;
  padding: 8px;
}
.search-list > .row.even {
  background-color: #f9f9f9;
}

.search-list .list-item {

}

.div-striped > .row {
  border-top: 1px solid #ddd;
  padding: 8px;
}
.div-striped > .row.even {
  background-color: #f9f9f9;
}

.list-outer {
  padding: 10px;
}

.dashboard-information > div {
  margin-bottom: 10px;
}

.invalid-value {
  color: red;
}
.search-name-icon {
  line-height: 60px;
}
.search-name-icon-taller {
  line-height: 75px;
}
.search-name-icon span {
  vertical-align: middle;
}
.search-name-icon-taller span {
  vertical-align: middle;
}
.search-company-icon-tall {
  vertical-align: middle;
  line-height: 60px;
}

.search-company-icon-taller {
  vertical-align: middle;
  line-height: 75px;
}

.search-company-icon-tall span {
  vertical-align: middle;
}
.search-company-icon-taller span {
  vertical-align: middle;
}

.search-name-text {
  display: inline-block;
  margin-left: 5px;
  margin-right: -5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-addr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
}

.rightbar {
  background: rgba(249, 249, 249, 1.0);
  border-left: 1px solid #e2e2e2;
  max-width: 300px;
  transition-property: max-width, margin-right;
  transition-duration: 1s;
  position: fixed;
  z-index: 200;
  top: 45px;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.rightbar>.smallLabel {
  opacity: 1.0;
  transition-property: opacity;
  transition-duration: 1s;
  transition-delay: 1s;
}

.faq-close {
  max-width: 0px;
  border-left: 1px solid #e2e2e2;
  background: rgba(226, 226, 226, 1.0);
  transition-property: max-width, margin-right;
  transition-duration: 1s;
}

.faq-close>.smallLabel {
  opacity: 0;
}

.faq-close.div {
  text-indent: 9999px
}

.smallLabel {
  font-size: 0.8em;
}

.leftbar{
  background-color: #f9f9f9;
  border-right: 4px solid #e2e2e2;
  max-width: 340px;
  height: 1000px;
}

.section {
  margin-left: -15px;
  width: 338px;
  border-bottom: 4px solid #e2e2e2;
}

.section > h3 {
  max-width: 50px;
}

.inlineStepper {
  width: 80px;
  margin-left: 10px;
}

.checkCenter {
  text-align: center;
  width: 20px;
}

.uccPrefs h2{
  color: #00aeef;
}

.lienPrefs h2{
  color: #00a651;
}

.collPrefs h2 {
  color: #f7941d;
}
.faq-alert {
  opacity: 0;
  transition: opacity 0.15s linear;

}
.faq-alert.in {
  opacity: 1;
  transition: opacity 0.15s linear;
  position: fixed;
  top: 80px;
  right: 610px;
  z-index: 1080;
}

#app {
  margin: 0px 0px 30px 0px;
}

/* Options */
/*
.search-list {
    overflow-y: auto;
    overflow-x: hidden;
}
*/
a, .clickable {
  cursor: pointer;
}

.clickable-table a { color: inherit; }

.btn-spaced {
  margin: 2px;
}

.btn-row {
  width: 100%;
  overflow: hidden;
  margin-top: 16px;
  vertical-align: middle;
}

.btn-nav {
  display: inline-block;
  margin-right: 8px;
}

.btn-config {
  float: right;
}

.config-panel {
  margin-right: 1px;
  border: 3px solid #73AD21;
  float: right;
  padding-bottom: 17px;
}

.config-text {
  margin-left: 15px;
  margin-right: 15px;
  margin-top:10px;
  float: left;

}

.config-dropdown {
  margin-left: 15px;

  margin-right: 15px;
  margin-top:10px;
  float: right;
}

.text-name {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  float: right;
}

.reporttable {
  min-width: 50%;
}
.btn-icon {
  vertical-align: middle;
}

.btn-text {
  display: inline-block;
  vertical-align: middle;
}

.clear-both {
  clear: both;
}

.detail-list {
  margin-top: 10px;
}

.btn-expand {
  float: left;
  margin-right: 8px;
}

.btn-collapse {
  float: left;
  margin-right: 6px;
  background-color: green;
  color: white;
}

.btn-collapse:hover {
  background-color: darkgreen;
  color: gray;
}

.btn-outline-dark {
  float: left;
  margin-right: 8px;
  pointer-events: none;
}

.report {
  margin-bottom: 25px;
}

.report-title {
  margin-bottom: 0px !important;
  display: inline-flex;
  align-items: center;
}

.title-box {
  display: flex;
  margin-bottom: 5px;
}

.sortable-table {
  margin-left: 0px;
  padding-left: 0px;
}

.sortable-table .table .header {
  display: table-header-group;
  border-bottom: 1px solid #d3d3d3;
}

.sortable-table.nodata {
  display: flex;
}

.hcol {
  background-color: #e5e8eb;
  border-color: #DFDFDF;
  justify-content: space-between;
  padding: 0 7px;
  height: 30px;
  align-items: center;
  color: #1F384D;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.5);
}

.closed {
  display: none !important
}

.btn-centered {
  display: flex;
  justify-content: center;
  margin: 2px;
}

.highlighted-value {
  background-color: yellow !important
}

.table-custom {
  width: 100%;
  max-width: 100%;
}
.table-custom td {
  word-break: break-all;
  width: 30px;
}

.labeled-input {
	padding-right: 20px;
}

.max-500{
  max-height: 500px;
  overflow: auto;
}